.it-header-slim-wrapper {
    background: var(--color-secondary);
}

.it-header-center-wrapper {
    background: var(--color-primary);
}

@media (min-width: 992px) {
    .it-header-navbar-wrapper {
        background: var(--color-primary);
    }
}

a, a.read-more {
    color: var(--color-primary);
}

a:hover, a.read-more:hover {
    color: var(--color-secondary);
}

#mobile-navmenu {
    background: var(--color-primary);
}

a.mobile-navmenu-item.nav-link {
    color: var(--color-primary);
}

a.mobile-navmenu-item.nav-link {
    color: var(--color-secondary);
}

.navbar-secondary li a.nav-link {
    color: var(--color-primary);
}

.navbar-secondary li a.nav-link {
    color: var(--color-secondary);
}

.chip.chip-primary {
    color: var(--color-primary);
    border-color: var(--color-primary);
}

.chip.chip-primary>.chip-label {
    color: var(--color-primary);
}

.chip.chip-primary:hover {
    background-color: var(--color-primary);
    border-color: var(--color-primary);
}

.card .categoryicon-top .icon, a.read-more .icon {
    fill: var(--color-primary);
}

.it-footer-main {
    background-color: var(--color-tertiary);
}

.it-footer-small-prints {
    background-color: var(--color-quaternary);
}

.news-title-link {
    color: var(--color-primary);
}

.news-title-link:hover {
    color: var(--color-secondary);
}

.sidebar-nav-link {
    color: var(--color-primary);
}

.sidebar-nav-link:hover {
    color: var(--color-secondary);
}

.attachment-box a {
    color: var(--color-primary);
}

.breadcrumb-container .breadcrumb .breadcrumb-item a {
    color: var(--color-primary);
}

.menu-item-link {
    color: var(--color-primary);
}

.menu-item-link:hover {
    color: var(--color-secondary);
}

.btn-primary {
    background-color: var(--color-primary);
    border-color: var(--color-primary);
}

.btn-primary:hover {
    background-color: var(--color-secondary);
    border-color: var(--color-secondary);
}

.document-icon {
    fill: var(--color-primary);
}

.document-category {
    color: var(--color-primary);
}

.it-header-block-title.calendar-header-block {
    background-color: var(--color-primary);
}

.swiper-pagination-bullet-active {
    background-color: var(--color-primary);
}

p.calendar-day-big {
    font-size: 2.8em;
}

.tags-home-tag-title {
    color: var(--color-primary);
}

.tags-home-icon-wrapper {
    fill: var(--color-primary);
}

.tags-home-icon {
    fill: var(--color-primary);
}

.link-list-wrapper ul li a:hover:not(.disabled) .icon.tags-home-icon {
    fill: var(--color-secondary);
}

.btn-primary.before-footer-btn {
    background-color: var(--color-primary);
}

.btn-primary.before-footer-btn:hover {
    background-color: var(--color-secondary);
}

.search-modal-title svg {
    fill: var(--color-primary);
}

svg.icon.search-header-icon-prepend.icon-lg {
    fill: var(--color-primary);
}

.mobile-follow-us {
    color: var(--color-primary);
}

.mobile-follow-us-list li a svg {
    fill: var(--color-primary);
}

.it-header-slim-wrapper .it-header-slim-wrapper-content .it-header-slim-right-zone button {
    background: var(--color-primary);
    border-radius: 0;
    width: 100%;
}

.it-header-slim-wrapper .it-header-slim-wrapper-content .it-header-slim-right-zone button:hover {
    background: var(--color-secondary);
}

.rating-wrapper {
    background: var(--color-secondary);
}

.it-header-center-wrapper .it-header-center-content-wrapper .it-search-wrapper button.rounded-icon svg {
    fill: var(--color-primary);
}

.it-footer-main p a {
    color: #fff;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 600;
}

.pagination .page-item .page-link.active {
    border: 1px solid var(--color-primary);
    color: var(--color-primary);
    pointer-events: none;
}

.search-filter-toggle-text {
    color: var(--color-primary);
}

.readdir-header {
    background: var(--color-primary);
    color: #fff;
    fill: #fff;
    padding: 5px;
    cursor: pointer;
}

.contacts-icon {
    fill: var(--color-primary);
}